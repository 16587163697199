import { useLoaderData } from "react-router-dom";
import { WeblinkHandler } from "../components/weblink-handler";

export function loader({ params }) {
  return params.order;
}

export function WeblinkPage(props) {
  const { appName, appAddress } = props;
  const order = useLoaderData();
  return (
    <WeblinkHandler order={order} appName={appName} appAddress={appAddress} />
  );
}
