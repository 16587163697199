import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, Typography } from "@mui/material";

import { WeblinkPage, loader as weblinkLoader } from "./routes/weblink-page";
import { ErrorPage } from "./routes/error-page";
import { customers } from "./config";

const root = ReactDOM.createRoot(document.getElementById("root"));

const routerConfig = [
  {
    path: "/",
    element: (
      <Typography align="center" m={3}>
        This website is not intended for general browsing.
      </Typography>
    ),
    errorElement: <ErrorPage />,
  },
];

customers.forEach((customer) =>
  routerConfig.push({
    path: customer.namespace + "/:order",
    element: (
      <WeblinkPage
        appName={customer.appName}
        appAddress={customer.appAddress}
      />
    ),
    errorElement: <ErrorPage />,
    loader: weblinkLoader,
  })
);

const router = createBrowserRouter(routerConfig);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <RouterProvider router={router} />
  </React.StrictMode>
);
