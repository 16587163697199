import PropTypes from "prop-types";
import { Box, Container, IconButton, Typography } from "@mui/material";
import { mobileModel, osName } from "react-device-detect";
import copy from "copy-to-clipboard";
import QRCode from "react-qr-code";

import { AppStoreIcon } from "./app-store-icon";

export const WeblinkHandler = (props) => {
  const { order, appName, appAddress } = props;

  function handleRedirection() {
    // first copy the order data to the clipboard for the app to use
    copy(order, { format: "text/plain" });
    // then redirect to the app installation page
    window.location.assign(appAddress);
  }

  if (
    mobileModel === "iPhone" ||
    (mobileModel === "iPad" && osName === "Mac OS")
  ) {
    // for iPhone or iPad Pro devices, the specific app should popup instead,
    // here we show them the app installation address in case the app is not
    // installed yet
    return (
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <Typography>
          You need to install the <strong>"{appName}"</strong> app on this
          device to fulfill the order:
        </Typography>
        <Box m={2} display="flex" justifyContent="center">
          <IconButton onClick={handleRedirection}>
            <AppStoreIcon height={60} />
          </IconButton>
        </Box>
        <Typography mt={3}>
          Or scan the QR code in the <strong>"{appName}"</strong> app from 
          another device:
        </Typography>
        <Box my={3} display="flex" justifyContent="center">
          <QRCode style={{ width: "40%", height: "auto" }} value={order} />
        </Box>
      </Container>
    );
  } else {
    // for all other devices (iPad, desktop, Android, etc.), only show the QR
    // code containing the order data
    return (
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <Typography>
          <strong>Action needed to fulfill the order:</strong> Scan the QR code
          in your mobile app <strong>"{appName}"</strong> to start 3D scanning
        </Typography>
        <Box mt={8} display="flex" justifyContent="center">
          <QRCode style={{ width: "40%", height: "auto" }} value={order} />
        </Box>
      </Container>
    );
  }
};

WeblinkHandler.propTypes = {
  order: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  appAddress: PropTypes.string.isRequired,
};
