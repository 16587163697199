export const customers = [
  {
    namespace: "xyken-test",
    appIdentifier: "com.xyken.SnugFitLink",
    appName: "SnugFit Weblink",
    appAddress:
      "https://apps.apple.com/us/app/snugfit-o-p-3d-scanner/id1672649360",
  },
  {
    namespace: "solo",
    appIdentifier: "com.xyken.SnugFitLink.Solo",
    appName: "SOLO GO",
    appAddress:
      "https://apps.apple.com/us/app/solo-go-foot-scanner/id6476235722",
  },
  {
    namespace: "viso",
    appIdentifier: "com.xyken.SnugFitLink.viSo",
    appName: "viSo ID3",
    appAddress: "https://apps.apple.com/us/app/solo-viso-id3/id6505079800",
  },
  {
    namespace: "feetid",
    appIdentifier: "com.xyken.SnugFitLink.FEETiD",
    appName: "FEET iD",
    appAddress: 
      "https://apps.apple.com/us/app/feet-id-scanner/id6736373688",
  },
];
