import { useRouteError } from "react-router-dom";
import { Typography } from "@mui/material";

export function ErrorPage() {
  const error = useRouteError();

  return (
    <>
      <Typography align="center" m={3}>
        Sorry, an unexpected error has occurred:
      </Typography>
      <Typography align="center" variant="subtitle2">
        {error.statusText || error.message}
      </Typography>
    </>
  );
}
